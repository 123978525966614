.menu-section::-webkit-scrollbar {
  display: none;
}

.menu-section {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.dialog {
  padding: 20px;
  background: white;
  position: absolute;
  z-index: 500;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(37, 37, 37, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog-content {
  background-color: white;
  margin: 0;
  width: 400px;
  height: 400px;
  display: flex;
  padding: 20px;
  flex-direction: column;
  border-radius: 10px;
  align-items: flex-start;
  justify-content: space-between;
  overflow-y: auto;
}