.fil0 {
  fill: #8E0303;
}

.fil1 {
  fill: #000E1F;
}

.fil2 {
  fill: none;
}

.fil3 {
  fill: #8E0303;
  fill-rule: nonzero;
}

.str0 {stroke:#8E0303;stroke-width:194.28;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str1 {stroke:#8E0303;stroke-width:194.28;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str2 {stroke:#8E0303;stroke-width:194.28;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str3 {stroke:#8E0303;stroke-width:194.28;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str4 {stroke:#8E0303;stroke-width:194.28;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str5 {stroke:#8E0303;stroke-width:194.28;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str6 {stroke:#8E0303;stroke-width:194.25;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str7 {stroke:#8E0303;stroke-width:194.28;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str8 {stroke:#8E0303;stroke-width:194.32;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str9 {stroke:#8E0303;stroke-width:194.28;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str10 {stroke:#8E0303;stroke-width:194.28;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str11 {stroke:#8E0303;stroke-width:194.28;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str12 {stroke:#8E0303;stroke-width:194.28;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}


.fil1-0 {fill:none}
.fil1-1 {fill:#FEFEFE}
.fil1-4 {fill:#000E1F}
.fil1-3 {fill:#8E0303}
.fil1-2 {fill:#FEFEFE;fill-rule:nonzero}
.fil1-5 {fill:#8E0303;fill-rule:nonzero}

.str1-2 {stroke:#FEFEFE;stroke-width:26.24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str1-21 {stroke:#8E0303;stroke-width:15.9;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str1-19 {stroke:#8E0303;stroke-width:15.9;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str1-18 {stroke:#8E0303;stroke-width:15.9;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str1-17 {stroke:#8E0303;stroke-width:15.9;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str1-25 {stroke:#8E0303;stroke-width:15.9;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str1-24 {stroke:#8E0303;stroke-width:15.9;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str1-15 {stroke:#8E0303;stroke-width:15.9;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str1-22 {stroke:#8E0303;stroke-width:15.9;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str1-26 {stroke:#8E0303;stroke-width:15.9;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str1-20 {stroke:#8E0303;stroke-width:15.9;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str1-16 {stroke:#8E0303;stroke-width:15.9;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str1-27 {stroke:#8E0303;stroke-width:15.9;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str1-23 {stroke:#8E0303;stroke-width:15.91;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str1-1 {stroke:#FEFEFE;stroke-width:107.83;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str1-0 {stroke:#FEFEFE;stroke-width:107.83;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str1-7 {stroke:#FEFEFE;stroke-width:67.39;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str1-14 {stroke:#FEFEFE;stroke-width:67.39;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str1-10 {stroke:#FEFEFE;stroke-width:67.39;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str1-3 {stroke:#FEFEFE;stroke-width:67.39;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str1-8 {stroke:#FEFEFE;stroke-width:67.39;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str1-11 {stroke:#FEFEFE;stroke-width:67.39;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str1-6 {stroke:#FEFEFE;stroke-width:67.39;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str1-13 {stroke:#FEFEFE;stroke-width:67.39;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str1-12 {stroke:#FEFEFE;stroke-width:67.39;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str1-9 {stroke:#FEFEFE;stroke-width:67.4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str1-5 {stroke:#FEFEFE;stroke-width:67.4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str1-4 {stroke:#FEFEFE;stroke-width:67.4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}

.str2-0 {stroke:#FEFEFE;stroke-width:14.56;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.fil2-0 {fill:#FEFEFE}
.fil2-2 {fill:#000E1F}
.fil2-1 {fill:#8E0303}

.str3-0 {stroke:#FEFEFE;stroke-width:5.39;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str3-3 {stroke:#FEFEFE;stroke-width:5.39;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str3-4 {stroke:#FEFEFE;stroke-width:5.39;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str3-2 {stroke:#FEFEFE;stroke-width:5.39;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str3-7 {stroke:#FEFEFE;stroke-width:5.39;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str3-1 {stroke:#FEFEFE;stroke-width:5.39;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str3-6 {stroke:#FEFEFE;stroke-width:5.39;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str3-5 {stroke:#FEFEFE;stroke-width:5.39;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str3-8 {stroke:#FEFEFE;stroke-width:5.39;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.fil3-3 {fill:none}
.fil3-0 {fill:#FEFEFE}
.fil3-1 {fill:#000E1F}
.fil3-2 {fill:#FEFEFE;fill-rule:nonzero}

.str4-0 {stroke:#FEFEFE;stroke-width:26.24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str4-2 {stroke:#8E0303;stroke-width:15.9;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str4-3 {stroke:#8E0303;stroke-width:15.9;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.str4-1 {stroke:#FEFEFE;stroke-width:49.2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
.fil4-3 {fill:none}
.fil4-0 {fill:#FEFEFE}
.fil4-2 {fill:#000E1F}
.fil4-1 {fill:#8E0303}